import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import { Context as ResponsiveContext } from "react-responsive"

// Components
import { Layout } from "../components/Layout"
import SEO from "../components/seo"
import { FaqAccordion } from "../components/FaqAccordion"
import FooterCTA from "../components/FooterCTA"
import BannerShape from "../components/BannerShape"

// Custom styles & Bootstrap
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import styles from "./faq.module.scss"

const FaqPage = ({ data }) => (
  <Layout>
    <SEO title="Frequently Asked Questions" />
    <Container
      fluid
      className={styles.container}
      css={css`
        @media (min-width: 576px) {
          background-image: url(${data.bannerBackground.publicURL});
        }
      `}
    >
      <ResponsiveContext.Provider value={{ maxWidth: 575 }}>
        <BannerShape />
        <h1
          css={css`
            @media (min-width: 576px) {
              display: none;
            }
          `}
        >
          Frequently Asked Questions
        </h1>
      </ResponsiveContext.Provider>
      <ResponsiveContext.Provider value={{ minWidth: 576 }}>
        <Container className={styles.bannerContainer}>
          <Row className="mt-5">
            <Col>
              <h1>Frequently Asked Questions</h1>
            </Col>
          </Row>
        </Container>
      </ResponsiveContext.Provider>
    </Container>
    <Container className={styles.faqContainer}>
      <FaqAccordion items={FAQS} />
    </Container>
    <FooterCTA />
  </Layout>
)

export default FaqPage

export const query = graphql`
  query {
    bannerBackground: file(relativePath: { eq: "banner/background.svg" }) {
      publicURL
    }
  }
`

const FAQS = [
  {
    title: "What is Accountable PH?",
    body:
      "Accountable PH is a team of competent professionals who are committed to provide high quality services to meet your business needs.",
  },
  {
    title: "Why use Accountable PH?",
    body:
      "We at Accountable PH value our clients, not only provide quality service, but we also help our clients avoid compliance risks that may arise in the course of their business. <br>We provide transparency on the tasks we perform. With cloud accounting, you can check if your records are up to date and if taxes have been filed!",
  },
  {
    title: "Do you use any accounting software in your bookkeeping services?",
    body:
      "Yes, we Use Xero Accounting software which runs on cloud. You shall be provided with your own login credentials to access the software.",
  },
  {
    title: "What is Xero Accounting Software?",
    body:
      "Xero is an online accounting software which helps you run your business from anywhere. It is cloud-based and can be accessed from any device with an active internet connection.",
  },
  {
    title: "What are our office hours?",
    body:
      "Our office hours run between 9:00 AM to 6:00 PM, Monday to Friday. <br>Since we use cloud accounting software, you may still access your financial data outside our business hours via internet. No need to worry!.",
  },
  {
    title: "How do I pay for your services?",
    body:
      "For an annual agreement, client shall provide a series of post-dated checks dated every 10th of the Month.",
  },
  {
    title: "How do I transmit my accounts information for bookkeeping and tax filing?",
    body:
      "All information may be transmitted electronically via email.",
  },
  {
    title: "Is digital bookkeeping secure?",
    body:
      "Yes, it is very secure. The industry recognized accounting software solution we use has encryption built in as standard, it uses multiple layers of firewalls and access controls.",
  },
  {
    title:
      "How do we access our accounting data if we leave Accountable PH?",
    body:
      "Your accounts information is yours. Accountable PH will provide you with extracted data which can be used to migrate to a new accounting software.",
  },
  {
    title: "I am so far behind; I have months of work to catch up on. My books are a mess!",
    body:
      "At Accountable PH we love a challenge we will ensure to have all your accounting records up to date in no time at all, giving you peace of mind to focus on other business activities.",
  },
  {
    title: "Does Accountable PH hold any financial records?",
    body:
      "All data provided is for the purposes of ensuring your books and accounting records are up to date. Should you decide to leave Accountable PH, all your data on file shall be deleted.",
  },
  {
    title: "How do I get started?",
    body:
      "All you need to do is complete the online inquiry form and <b><a href='https://www.accountable.ph/lets-talk'>Get Started</a></b>. We will assess this information for FREE and contact you back at a time and the number you have stated as convenient.",
  },
  {
    title: "Will my life be stress-free?",
    body:
      "Yes, we aim to take all your pain and stress away so you can focus on your business.",
  },
  {
    title: "How can I transition from in-house to outsourced accounting?",
    body:
      "We will provide you with a list of on-boarding requirements. Once requirements have been received, we will process the transition.",
  },
  {
    title: "When should I consider outsourcing my accounting?",
    body:
      "For small and medium size businesses, it would be advantageous to outsource your bookkeeping and accounting responsibilities. By outsourcing, you will acquire the service of competent professionals at a cheaper cost compared to in-house accountants. Not only you will save from staff’s salary, but it will also take away the worry of providing the staff/s their computer and other equipment.",
  },
]
